
<template>
        <div class="col-md-7 col-sm-7 mt-10 boxmain">
         
          <section class="container">
           
                  

             <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t("question.name_question")}}

                  <a
                      :href="$router.resolve({name: 'question',params:{locale:$i18n.locale}}).href"
                     :title="$t('question.name_question')">
                    {{$t('news.see')}}
                  </a>
                </h2>

              </div>
            </div>
          </div>




          <div class="request-a-call-back pdb-md-30" v-if="question==null">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mrb-md-60">
                  <div class="faq-block">
                    <div class="accordion">

                      <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                      <div class="accordion-item">
                        <div class="accordion-header active">
                          <h5 class="title">{{$t('question.question')}} : <Skeleton></Skeleton></h5>
                          <span class="fa fa-plus"></span>
                        </div>
                        <div class="accordion-body">
                          <p>{{$t('question.answer')}}:
                           <Skeleton></Skeleton>
                          </p>
                        </div>
                      </div>
                      </SkeletonTheme>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="request-a-call-back pdb-md-30" v-else>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mrb-md-60">
                  <div class="faq-block">
                    <div class="accordion">

                      <div class="accordion-item" v-for="(q,k) in question" :key="k">
                        <div class="accordion-header " >
                          <h5 class="title" v-html="$t('question.question')+': '+sript_tag(q.name)" >


                          </h5>
                          <span class="fa fa-plus"></span>
                        </div>
                        <div class="accordion-body">
                          <p v-html="$t('question.answer')+': '+sript_tag(q.answer)">

                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          </section>


        </div>


</template>




<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";


  import axios from 'axios';
export default {


  data() {
    return {


      myurl:window.my_url,
      my_data:null,
     departments:null,
      message:false,
      capcha:null,
      meta:{
        title: "Liên hệ",
        description: "Ý KIẾN GÓP Ý VỚI CHÚNG TÔI"
      },
      my_capcha:"",
      question:null

    };
  },
  metaInfo(){
     return {
        title:this.meta.title,
        description:this.meta.description
     }
  },
  components: {
    Skeleton,
    SkeletonTheme



  },
  created(){

     //s this.$i18n.locale
          var t1=  axios.get(window.my_api+"api/department/get-departments", {
                                 headers: {
                                   language: 'vn'
                                 }
                                })

              .then( (res)=>{

                  if(res.status==200){

                       this.departments=res.data.data;


                  }else {
                    console.log("ERROR",res);
                  }


                });

               var t2=        axios.get(window.my_api+"api/question/get-home-questions",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
                .then( (res)=>{
                  if(res.status==200){
                      this.question=res.data.data.slice(0,6);//.filter(n => n.language==this.$i18n.locale);


                  }
                });

          Promise.all([t1,t2]).then(()=>{
                 this.is_loading=false;
                 this.reloadCapcha();
          });

  },

  methods:{
    sript_tag(string){
         string= string.replace(/<\/?[^>]+>/ig, " ").toString();

           return string;
    },
    reloadCapcha(){
         axios.post(window.my_api+"api/captchar/generate-captchars")
                  .then( (res)=>{

                    if(res.status==200){

                         this.capcha=res.data.data;
                         //console.log(this.capcha);


                    }else {
                      console.log("ERROR",res);
                    }


                });
    },
      onSubmit(event){
           event.preventDefault();
           if(this.my_capcha.toUpperCase()!=this.capcha.captchaCode.toUpperCase()){
              alert("Vui lòng nhập đúng capcha");
              this.reloadCapcha();
              return false;
           }
           var form_data = new FormData(document.getElementById('my_submit'));
           axios.post(window.my_api+"api/contact/create-or-update-contact",form_data)
                .then((res)=>{
                    if(res.status==200){

                      if(res.data.errors.length>0){
                          alert("Vui lòng điền đủ thông tin.");
                      }else {
                          document.getElementById('my_submit').reset();
                          alert(" Cảm ơn bạn đã liên hệ đến chúng tôi. Chúng tôi sẽ liên hệ đến bạn một cách sớm nhất. ");

                      }

                    }
                });

           return false;
      }

  }
}
</script>
